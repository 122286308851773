import React,{useState,useRef} from 'react'
import about_1 from "../../Assets/Images/about-1.png"
import shape3 from "../../Assets/Images/shape-3.png"
import shape4 from "../../Assets/Images/shape-4.png"
import "../../Assets/Styles/Sections/Section3.css"
import AOS from 'aos';
import ModalPage from './ModalPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import 'aos/dist/aos.css'
const Section3 = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000
    });
    return (
        <div className='about-area ptb-100 mt-5 pt-5'>
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-lg-6 col-md-6 col-12' data-aos="fade-up" data-aos-delay="50">
                        <div className='about-image d-md-flex d-none'>
                            <img src={about_1} className="aboutsubimg" class="img-fluid" alt="Responsive image"></img>
                            <div className='about-shape-1'>
                                <img src={shape3}></img>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12' data-aos="fade-up" data-aos-delay="50" >
                        <div className='about-content'>
                            <h5 className='mb-4'>Looking for Professional Web design agency in Chennai ?</h5>
                            <h2> WEBSITES DEVELOPMENT SERVICES STARTING AT ONLY 15999/-</h2>
                            <span className='mt-4'>DOMAIN | HOSTING | PROFESSIONAL EMAILS</span>
                            <p>We are specialized in creating and offering innovative web solutions.
                                Our team consists of talented professionals with a wide range of
                                experience in Web design, Web development, E-commerce, and Digital Marketing.
                            </p>
                            <p>Our team of talented web designers, skilled web developers, and dedicated support
                                team have made us one of the most popular website development firms in Chennai.
                                Several industries have benefited from our premium web development
                                services in Chennai, enabling them to maximize their potential,
                                expand their market share, and increase their revenue.
                            </p>
                            <div class="btn-area  d-flex justify-content-md-start justify-content-center mt-3">
                                <a onClick={showModal} class="btn btn-default1">REACH OUT US</a>
                            </div>
                            <ModalPage
                            onFinishFailed={onFinishFailed}
                            onFinish={onFinish}
                            handleCancel={handleCancel}
                            showModal={showModal}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            sendEmail={sendEmail}
                            form={form}
                        />
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-shape-2 d-md-flex d-none'>
                <img src={shape4}></img>
            </div>
        </div>
    )
}

export default Section3