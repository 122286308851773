import './App.css';
import './index.css'
import LandingPage from './Components/MainSource/LandingPage/LandingPage';

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
