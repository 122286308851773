import React from 'react'
import "../../Assets/Styles/Sections/Section6.css"
import insta from "../../Assets/Images/insta.png"
import girlLap from "../../Assets/Images/girlLap.png"
import paint from "../../Assets/Images/pain.png"
import groups from "../../Assets/Images/group.png"
import design from "../../Assets/Images/design.png"
import content from "../../Assets/Images/content.png"
import AOS from 'aos';
import 'aos/dist/aos.css'

const Section6 = () => {
  AOS.init({
    duration: 1000
  });
  return (
    <div className=''>
      <div class="square-shape-one"></div>
      <div class="square-shape-two"></div>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-md-8'>
            <div className='title1'>
              <h2>Our <strong> Digital Marketing Services</strong> will help you stay profitable for years to come</h2>
            </div>
          </div>
        </div>
        <div className='row ' >
          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#44e2d3" }}>
              <span class="left-b" style={{ borderTop: "2px solid #44e2d3", borderLeft: "2px solid #44e2d3" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #44e2d3", borderRight: "2px solid #44e2d3" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Logo.width-500.format-webp.webp" class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content col-12">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Google Ads</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #44e2d3", borderLeft: "2px solid #44e2d3" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #44e2d3", borderRight: "2px solid #44e2d3" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#f25202" }}>
              <span class="left-b" style={{ borderTop: "2px solid #f25202", borderLeft: "2px solid #f25202" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #f25202", borderRight: "2px solid #f25202" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={insta} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Facebook & Instagram Marketing</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #f25202", borderLeft: "2px solid #f25202" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #f25202", borderRight: "2px solid #f25202" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#5b8fbf" }}>
              <span class="left-b" style={{ borderTop: "2px solid #5b8fbf", borderLeft: "2px solid #5b8fbf" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #5b8fbf", borderRight: "2px solid #5b8fbf" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src="https://www.linkplux.com/wp-content/uploads/2022/08/FLY-TRIP1.png" class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Lead Generation</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #5b8fbf", borderLeft: "2px solid #5b8fbf" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #5b8fbf", borderRight: "2px solid #5b8fbf" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#e66ca8" }}>
              <span class="left-b" style={{ borderTop: "2px solid #e66ca8", borderLeft: "2px solid #e66ca8" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #e66ca8", borderRight: "2px solid #e66ca8" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={girlLap} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Social Media Management</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #e66ca8", borderLeft: "2px solid #e66ca8" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #e66ca8", borderRight: "2px solid #e66ca8" }}></span>
            </a>
          </div>


          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#f89f07" }}>
              <span class="left-b" style={{ borderTop: "2px solid #f89f07", borderLeft: "2px solid #f89f07" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #f89f07", borderRight: "2px solid #f89f07" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={content} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Content Marketing</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #f89f07", borderLeft: "2px solid #f89f07" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #f89f07", borderRight: "2px solid #f89f07" }}></span>
            </a>
          </div>


          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#2E2E2E" }}>
              <span class="left-b" style={{ borderTop: "2px solid #2E2E2E", borderLeft: "2px solid #2E2E2E" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #2E2E2E", borderRight: "2px solid #2E2E2E" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={design} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Graphic Design</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #2E2E2E", borderLeft: "2px solid #2E2E2E" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #2E2E2E", borderRight: "2px solid #2E2E2E" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#005aa9" }}>
              <span class="left-b" style={{ borderTop: "2px solid #005aa9", borderLeft: "2px solid #005aa9" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #005aa9", borderRight: "2px solid #005aa9" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={groups} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Website Design</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #005aa9", borderLeft: "2px solid #005aa9" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #005aa9", borderRight: "2px solid #005aa9" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#8C001A" }}>
              <span class="left-b" style={{ borderTop: "2px solid #8C001A", borderLeft: "2px solid #8C001A" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #8C001A", borderRight: "2px solid #8C001A" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src={paint} class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Website Development</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #8C001A", borderLeft: "2px solid #8C001A" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #8C001A", borderRight: "2px solid #8C001A" }}></span>
            </a>
          </div>

          <div className='col-md-4' data-aos="fade-up" data-aos-delay="100" >
            <a href='' className='cs-links' style={{ background: "#95BA61" }}>
              <span class="left-b" style={{ borderTop: "2px solid #95BA61", borderLeft: "2px solid #95BA61" }}></span>
              <span class="right-b" style={{ borderTop: "2px solid #95BA61", borderRight: "2px solid #95BA61" }}></span>
              <div class="list-case-studies">
                <div class="case-studies-image">
                  <img src="https://www.caorda.com/wp-content/uploads/2021/04/video-production-services-marketing.png" class="img-fluid" alt="Responsive image" />
                </div>
              </div>
              <div class="case-studies-content">
                <div class="case-studies-title d-flex justify-content-center">
                  <h2>Video Creation</h2>
                </div>
              </div>
              <span class="left-bottom-b" style={{ borderBottom: "2px solid #95BA61", borderLeft: "2px solid #95BA61" }}></span>
              <span class="right-bottom-b" style={{ borderBottom: "2px solid #95BA61", borderRight: "2px solid #95BA61" }}></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section6