import React from 'react'
import "../../Assets/Styles/Sections/Section16.css"
import best from "../../Assets/Images/best.jpg"
import { HiArrowNarrowRight } from "react-icons/hi"

const Section16 = () => {
    return (
        <div className='bestDigitalMain'>
            <div className='container'>
                <div className='col-12 d-flex justify-content-center mt-5 mb-5'>
                    <h2>BEST DIGITAL MARKETING COMPANY</h2>
                </div>
                <div className='col-12 d-flex flex-md-row flex-column gap-4'>
                    <div className='col-12 col-md-6 bestPara'>
                        <p>DLK Technologies is a best digital marketing company specializes in all things Google. As we are the best Gooogle Adwords & PPC Managment company, Our team of experts have extensive knowledge of the latest trends and best practices in Google ads, ensuring that every campaign they run is effective and delivers results. We understand the importance of targeting the right audience with the right message at the right time, and work closely with clients to develop custom campaigns that are tailored to their specific needs and goals. Whether you're looking to generate leads, increase sales, or build your brand.</p>
                        <p>We help you develop and execute a social media strategy that engages your target audience and builds a loyal followers with our social media management services, Facebook and Instagram promotion. We are best website development company in chennai, if you're starting from scratch or looking to revamp an existing site, DLK Technologies has the expertise to help you create a website that stands out from the crowd and drives conversions.</p>
                    </div>
                    <div className='col-12 col-md-6 px-3 d-md-flex d-lg-flex d-none'>
                        <img className='img-fluid' src={best} />
                    </div>
                </div>
                <div className='mt-4'>
                    <h3>ROI Driven Digital Marketing Services</h3>
                    <div className='ROIDriven'>
                        <ul className='elementor-icon-list-items'>
                            <li className='elementor-icon-list-item mt-4'>
                                <span className='elementor-icon-list-text'>
                                    We offer excellent SEO services, digital marketing and reach out to the customers and clients constantly. We acquire experienced staff with resources, experienced digital marketers, and implement the optimum solution and effective result in accelerating with efficient results:
                                </span>
                            </li>
                            <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right">
                                        <HiArrowNarrowRight />
                                    </i>
                                </span>
                                <span class="elementor-icon-list-text">Clear Goals and Objectives</span>
                            </li>
                            <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right">
                                        <HiArrowNarrowRight />
                                    </i>
                                </span>
                                <span class="elementor-icon-list-text">Targeted Audience</span>
                            </li>
                            <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right">
                                        <HiArrowNarrowRight />
                                    </i>
                                </span>
                                <span class="elementor-icon-list-text">Consistent Efforts</span>
                            </li>
                            <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right">
                                        <HiArrowNarrowRight />
                                    </i>
                                </span>
                                <span class="elementor-icon-list-text">Data-Driven Optimization</span>
                            </li>
                            <li class="elementor-icon-list-item mb-5">
                                <span class="elementor-icon-list-icon">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right">
                                        <HiArrowNarrowRight />
                                    </i>
                                </span>
                                <span class="elementor-icon-list-text">Focus on ROI Metrics</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section16
