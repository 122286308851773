import React from 'react'
import "../../Assets/Styles/Sections/Section9.css"
import Carousel from "react-multi-carousel";
import client1 from "../../Assets/Images/clientWork1.png"
import client2 from "../../Assets/Images/clientWork2.png"
import client3 from "../../Assets/Images/clientWork3.png"
import "react-multi-carousel/lib/styles.css";
import { BsPeopleFill, BsArrowsMove } from "react-icons/bs"
import { GrUserSettings } from "react-icons/gr"

const Section9 = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div id='ourwork' className='testimonials-area mb-5'>
            <div className='container'>
                <div className='section-title'>
                    <h2>OUR WORK</h2>
                </div>
                <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    // autoPlaySpeed={1000}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className='caroseulMain'>
                        <div className='container col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart'>
                            <div className='col col-md-6 col-12'>
                                <div className=' d-flex justify-content-center mt-md-2'>
                                    <img src={client1} className='' width="250"></img>
                                </div>
                            </div>
                            <div class="vl d-md-flex d-none"></div>
                            <div className='container col col-md-6 col-12 mb-md-0 mb-5 pt-4 px-md-5'>
                                <div className=' d-flex flex-column justify-content-start'>
                                    <div className='caroseultitle d-flex justify-content-md-start justify-content-center px-md-3'>
                                        <h2>Client : Eve's Cafe</h2>
                                    </div>
                                    <div className='d-flex flex-row col-md-12 gap-md-5 mt-3 mb-md-5'>
                                        <div className='col-md-2 col-3 circleWithIcon'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsPeopleFill size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>223%+</h2>
                                                </div>
                                                <h5>increase in Sales</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsArrowsMove size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>83%</h2>
                                                </div>
                                                <h5>increase in returning customers</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <GrUserSettings size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>100%</h2>
                                                </div>
                                                <h5>increase in web traffic</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='caroseulMain'>
                        <div className='container col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart'>
                            <div className='col col-md-6 col-12'>
                                <div className=' d-flex justify-content-center mt-md-2'>
                                    <img src={client2} className='' width="250"></img>
                                </div>
                            </div>
                            <div class="vl d-md-flex d-none"></div>
                            <div className='col col-md-6 col-12 mb-md-0 mb-5 pt-4 px-md-5'>
                                <div className=' d-flex flex-column justify-content-start'>
                                    <div className='caroseultitle d-flex justify-content-md-start justify-content-center px-md-3'>
                                        <h2>Client : Clean Air India</h2>
                                    </div>
                                    <div className='d-flex flex-row col-md-12 gap-md-5 mt-3  mb-md-5'>
                                        <div className='col-md-2 col-3 circleWithIcon '>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsPeopleFill size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>186%+</h2>
                                                </div>
                                                <h5>increase in Enquires</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsArrowsMove size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>75%</h2>
                                                </div>
                                                <h5>increase in returning customers</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <GrUserSettings size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>90%</h2>
                                                </div>
                                                <h5>increase in web traffic</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='caroseulMain'>
                        <div className='container col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart'>
                            <div className='col col-md-6 col-12'>
                                <div className=' d-flex justify-content-center mt-md-2'>
                                    <img src={client3} className='' width="250"></img>
                                </div>
                            </div>
                            <div class="vl d-md-flex d-none"></div>
                            <div className='col col-md-6 col-12 mb-md-0 mb-5 pt-4 px-md-5'>
                                <div className=' d-flex flex-column justify-content-start'>
                                    <div className='caroseultitle d-flex justify-content-md-start justify-content-center px-md-3'>
                                        <h2>Client : QSCert Singapore</h2>
                                    </div>
                                    <div className='d-flex flex-row col-md-12 gap-md-5 mt-3 mb-md-5'>
                                        <div className='col-md-2 col-3 circleWithIcon  '>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsPeopleFill size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>152%+</h2>
                                                </div>
                                                <h5>increase in Enquires</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <BsArrowsMove size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>85%</h2>
                                                </div>
                                                <h5>increase in returning customers</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-3  circleWithIcon mb-md-5'>
                                            <div className='d-flex justify-content-center mt-3'>
                                                <GrUserSettings size={50} />
                                            </div>
                                            <div className='circleWithText mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <h2>100%</h2>
                                                </div>
                                                <h5>increase in web traffic</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </Carousel>
            </div>
        </div>
    )
}

export default Section9