import React from 'react'
import "../../Assets/Styles/Sections/Section2.css"
import about from "../../Assets/Images/aboutus.jpeg"
import shape1 from "../../Assets/Images/shape-1.png"
const Section2 = () => {
    return (
        <div className='features-area'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-5 d-flex justify-content-center'>
                        <img src={about} className="aboutusImg" />
                    </div>
                    <div className='col-md-7 mt-4'>
                        <h2 className='aboutText d-flex justify-content-md-start justify-content-center'>ABOUT US</h2>
                        <p className='aboutPara'>DLK Technologies Private Limited IT Solutions, headquartered in Chennai, India,
                            is a HI-FI web development and digital marketing company who is excellent in the field of
                            innovative <span style={{fontSize:"22px"}}> Website Designing and Search Engine Optimization (SEO), Domain Registration,
                            Web Hosting, E-Commerce Solutions, Graphic Designing, Social Media Marketing, and
                            other Software related programs.</span>
                        </p>
                        <div class="btn-area  d-flex justify-content-md-start justify-content-center px-4">
                            <a onClick={() => window.open("https://dlktech.co.in/")} class="btn btn-default1">READ MORE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='features-shape-1'>
                <img src={shape1} />
            </div>
        </div>
    )
}

export default Section2