import React from 'react'
import "../../Assets/Styles/Sections/Section14.css"

const Section14 = () => {
  return (
    <div className='container'>
      <div className='container main-content-marketing'>
        <div className='main-content-mainTopics'>
          <div className='main-content-marketingarea'>
            <h2>
              Designing
            </h2>
          </div>
          <div className='main-content-list mt-4'>
            <ul className='elementor-icon-list-items elementor-inline-items'>
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/small-business-website/">
                  <span class="elementor-icon-list-text">Small Business Website</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/corporate-website/">
                  <span class="elementor-icon-list-text">Corporate Website</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/ecommerce-website/">
                  <span class="elementor-icon-list-text">E-Commerce Website</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/multi-vendor-ecommerce-marketplace/">
                  <span class="elementor-icon-list-text">Multi Vendor Ecommerce Market Place</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/landing-page-designing/">
                  <span class="elementor-icon-list-text">Landing Page Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/psd-to-html-conversion/">
                  <span class="elementor-icon-list-text">PSD to HTML Conversion</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/responsive-web-designing/">
                  <span class="elementor-icon-list-text">Responsive Web Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/custom-website-designing/">
                  <span class="elementor-icon-list-text">Custom Web Designing</span> &nbsp;                 
                </a>
              </li>
            </ul>
          </div>

          <div className='main-content-marketingarea mt-4'>
            <h2>
            Development
            </h2>
          </div>
          <div className='main-content-list mt-4'>
            <ul className='elementor-icon-list-items elementor-inline-items'>
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/php-web-development/">
                  <span class="elementor-icon-list-text">PHP Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/cms-web-development/">
                  <span class="elementor-icon-list-text">CMS Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/codeigniter-web-development/">
                  <span class="elementor-icon-list-text">CodeIgnitor Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/joomla-web-development/">
                  <span class="elementor-icon-list-text">Joomla Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/wordpress-web-development/">
                  <span class="elementor-icon-list-text">Wordpress Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/magento-web-development/">
                  <span class="elementor-icon-list-text">Megento Web Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/b2b-portal-development/">
                  <span class="elementor-icon-list-text">B2B Portal Development</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/b2c-portal-development/">
                  <span class="elementor-icon-list-text">B2C Portal Development</span> &nbsp;                 
                </a>
              </li>
            </ul>
          </div>


          <div className='main-content-marketingarea mt-4'>
            <h2>
            Digital Marketing
            </h2>
          </div>
          <div className='main-content-list mt-4'>
            <ul className='elementor-icon-list-items elementor-inline-items'>
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/seo-services/">
                  <span class="elementor-icon-list-text">SEO Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/local-seo-services/">
                  <span class="elementor-icon-list-text">Local SEO Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/social-media-marketing/">
                  <span class="elementor-icon-list-text">Social Media Marketing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/social-media-management/">
                  <span class="elementor-icon-list-text">Social Media Management</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/content-marketing-services/">
                  <span class="elementor-icon-list-text">Content Marketing Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/youtube-video-promotion/">
                  <span class="elementor-icon-list-text">Youtube Video Promotion</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/email-marketing-services/">
                  <span class="elementor-icon-list-text">Email Marketing Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/sms-marketing-services/">
                  <span class="elementor-icon-list-text">SMS Marketing Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/google-map-promotion/">
                  <span class="elementor-icon-list-text">Google Map Promotion</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/google-adwords-ppc-management/">
                  <span class="elementor-icon-list-text">Google Adwords PPC Management</span> &nbsp;     
                  <span> |</span>      
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/facebook-advertising/">
                  <span class="elementor-icon-list-text">Facebook Advertising</span> &nbsp;     
                  <span> |</span>      
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/google-promotion-services/">
                  <span class="elementor-icon-list-text">Google Promotion Services</span> &nbsp;     
                  <span> |</span>      
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/writing-services/">
                  <span class="elementor-icon-list-text">Writing Services</span> &nbsp;     
                </a>
              </li>
            </ul>
          </div>

          <div className='main-content-marketingarea mt-4'>
            <h2>
            Graphics
            </h2>
          </div>
          <div className='main-content-list mt-4'>
            <ul className='elementor-icon-list-items elementor-inline-items'>
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/logo-designing/">
                  <span class="elementor-icon-list-text">Logo Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/brochure-designing/">
                  <span class="elementor-icon-list-text">Brochure Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/resume-and-cv-designing/">
                  <span class="elementor-icon-list-text">Resume & CV Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/flyers-posters-designing/">
                  <span class="elementor-icon-list-text">Flyers & Posters Designing</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/graphics-designing-for-social-media/">
                  <span class="elementor-icon-list-text">Graphic Designing for Social Media</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/banner-designing-services/">
                  <span class="elementor-icon-list-text">Banner Designing Services</span> &nbsp;
                  <span> |</span>
                </a>
              </li>&nbsp;
              <li className='elementor-icon-list-item elementor-inline-item'>
                <a href="https://dlktech.co.in/photoshop-editing/">
                  <span class="elementor-icon-list-text">Photoshop Editing</span> &nbsp;                 
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section14