import React, { createRef,useEffect } from 'react'
import { Input, Modal } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-toastify/dist/ReactToastify.css';
const { TextArea } = Input;

const ModalPage = ({ form, sendEmail, setIsModalOpen, handleCancel, isModalOpen }) => {
    const recaptchaRef = createRef();
    function onChange(value) {
        setIsModalOpen(true)
        console.log("Captcha value:", value);

    }
    return (
        <div>
            <Modal open={isModalOpen} onCancel={handleCancel} footer={[]}>
                <h4 className=''>Enquiry Now</h4>
                <div className='px-md-4 pt-3'>
                    <form ref={form} onSubmit={sendEmail}>
                        <div>
                            <label for="exampleInputEmail1" class="form-label formLabel">
                                Name
                            </label>
                            <input
                                id="ContactForm1_contact-form-name"
                                size="30"
                                required
                                type="text"
                                name="user_name"
                                class="form-control"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="exampleInputPassword1" class="form-label formLabel">
                                Email
                            </label>
                            <input
                                id="ContactForm1_contact-form-email"
                                required
                                size="30"
                                type="email"
                                name="user_email"
                                class="form-control"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="exampleInputPassword1" class="form-label formLabel">
                                Phone
                            </label>
                            <input
                                required
                                type="number"
                                class="form-control"
                                id="ContactForm1_contact-form-phone"
                                name="user_phone"
                                size="11"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="exampleInputPassword1" class="form-label formLabel">
                                Message
                            </label>
                            <textarea
                                required
                                id="ContactForm1_contact-form-email-message"
                                class="form-control"
                                rows="3"
                                name="message"
                            ></textarea>
                        </div>
                        <div className='mt-2'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Lf5ZhslAAAAAH_E_1wxqGjNgemwye4d6tMIn5wj"
                                onChange={onChange}
                            />
                        </div>
                        <div class="col-12 text-center mt-3 ">
                            <div className='banner-btn'>
                                <button
                                    type="submit"
                                    id="ContactForm1_contact-form-submit"
                                    className='default-btn'
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalPage