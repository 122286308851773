import React from 'react'
import "../../Assets/Styles/Sections/Section11.css"
import client1 from "../../Assets/Images/client1.png"
import client2 from "../../Assets/Images/client2.png"
import client3 from "../../Assets/Images/client3.png"
import client4 from "../../Assets/Images/client4.png"

const Section11 = () => {
    return (
        <div className='mb-5'>
            <div className='section-title1 mt-5'>
                <h5>Our Clients</h5>
                <h2>Market Industry Leaders</h2>
                <h6 className='mt-2'>"We Are A Proven Internet Marketing & SEO Company With Experience."</h6>
            </div>
            <div className='container col-12 col-md-12 mt-5'>
                <div className='container col-12 col-md-12 text-center'>
                    <p>At DLK, we have over 6+ years combined experience in SEO and Internet Marketing. We have assisted over 1,000 websites to date with our proven white hat Search Engine Optimization (SEO) techniques to get top rankings. We believe in getting results for our Internet Marketing & SEO clients and not committing them to any kind of long term contracts!</p>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='container card clientsCard'>
                    <div className='col-12 col-md-12 d-flex flex-md-row flex-column'>
                        <div className='col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client1} />
                        </div>
                        <div class="vlclient d-md-flex d-none"></div>
                        <div className='col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client2} />
                        </div>
                        <div class="vlclient d-md-flex d-none"></div>
                        <div className='col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client3} />
                        </div>
                        <div class="vlclient d-md-flex d-none"></div>
                        <div className='col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client4} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section11