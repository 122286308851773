import React,{useRef,useState} from 'react'
import "../../Assets/Styles/Sections/Section5.css"
import man1 from "../../Assets/Images/man1.png"
import man2 from "../../Assets/Images/man2.png"
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ModalPage from './ModalPage';

const Section5 = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000
    }); 
    return (
        <div className='pt-5 mb-5'>
            <div id="card-container1" data-offset="2">
                <div class="pg d-md-flex d-none" >
                    <img src={man1} data-aos="fade-up-right" data-aos-delay="50" />
                </div>
                <div id="card1">
                    <div class="shine1"></div>
                    <div class="text-block1 mt-3">
                        <h1 class="meg_title1">DO YOU WANT SUCCESS IN DIGITAL LIFE?</h1>                        
                        <div class="btn-area d-flex justify-content-center mt-3 mt-md-5" >
                            <a onClick={showModal} class="btn btn-default">CONTACT US</a>
                        </div>
                        <ModalPage
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        handleCancel={handleCancel}
                        showModal={showModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        sendEmail={sendEmail}
                        form={form}
                    />
                    </div>
                </div>
                <div class="pg1 d-md-flex d-none" >
                    <img src={man2} data-aos="fade-up-left" data-aos-delay="50" />
                </div>
            </div>
        </div>
    )
}

export default Section5