import React from 'react'
import Navbar from '../Navbar/Navbar'
import Section1 from '../Sections/Section1'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
import Section6 from '../Sections/Section6'
import Section7 from '../Sections/Section7'
import Section8 from '../Sections/Section8'
import Section9 from '../Sections/Section9'
import Section10 from '../Sections/Section10'
import Section11 from '../Sections/Section11'
import Section12 from '../Sections/Section12'
import Section13 from '../Sections/Section13'
import Section15 from '../Sections/Section15'
import Section14 from '../Sections/Section14'
import Section16 from '../Sections/Section16'
import call from "../../Assets/Images/call-now.gif"
import whatsapp from "../../Assets/Images/whatsapp.png"
import "../../Assets/Styles/LandingPage/LandingPage.css"
import inr from "../../Assets/Images/inr.png"

const LandingPage = () => {
   
    return (
        <div>
            <div class="jsn-air-balloon request_quote">
                <a
                    href="https://api.whatsapp.com/send?phone=+91 7299951536&amp;text=Hi! I Would Like To Know More About Your Service." target="_blank">
                    <img src={whatsapp}
                        alt="DLK technologies Whatsapp"
                        title="DLK technologies Whatsapp"
                    />
                </a>
            </div>
            <div class="jsn-air-balloon-phone request_quote_phone">
                <a href="tel:+91-7299951536 " title="+91-7299951536 ">
                    <img src={call} alt="DLK technologies call" title="DLK technologies call" />
                </a>
            </div>
            <section>
                <Navbar />
            </section>
            <section>
                <Section1 />
            </section>
            <section id='about' className='pt-md-5'>
                <Section2 />
            </section>
            <section>
                <Section16 />
            </section>
            <section>
                <Section3 />
            </section>
            <section>
                <Section4 />
            </section>
            <section>
                <Section5 />
            </section>
            <section id='services' className='page-separator case-studies'>
                <Section6 />
            </section>
            <section>
                <Section7 />
            </section>
            <section className='contact-section '>
                <Section8 />
            </section>
            <section>
                <Section9 />
            </section>
            <section>
                <Section10 />
            </section>
            <section>
                <Section11 />
            </section>
            <section>
                <Section12 />
            </section>
            <section>
                <Section13 />
            </section>
            <section>
                <Section14 />
            </section>
            <section className='mb-5'>
                <Section15 />
            </section>
            <section class="footer-sticky hidden-xs hidden-sm">
                <div class="col-lg-7 center_bx_foot">
                    <div class="num_card">
                        <span class="text">For Further Assistance :</span>
                        <span class="in_num">
                            <img src={inr} alt="flag_in" />
                            <a aria-label="India flag" href="tel:+91 7299951536 "> +91-7299951536 </a>
                        </span>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default LandingPage