import React, { useEffect } from 'react'
import "../../Assets/Styles/Sections/Section15.css"
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs"
import { GrTwitter, GrLinkedinOption } from "react-icons/gr"
import footerLogo from "../../Assets/Images/footerLogo.jpg"
import face from "../../Assets/Images/face.webp"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Section15 = () => {

    return (
        <div className='promo-text-area1 mt-5'>
            <div className='container'>
                <div>
                    <footer class="new_footer_area bg_color">
                        <div class="new_footer_top">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="f_widget company_widget wow fadeInLeft mainContents" data-wow-delay="0.2s" >
                                            <h3 class="f-title f_600 t_color f_size_18">Get in Touch</h3>
                                            <a href='/'>
                                                <img src={footerLogo} />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 px-md-5">
                                        <div class="f_widget about-widget pl_70 wow fadeInLeft mainContents" data-wow-delay="0.4s" >
                                            <h3 class="f-title f_600 t_color f_size_18">Profile</h3>
                                            <ul class="list-unstyled f_list">
                                                <li>
                                                    <Link to="about" smooth={true} duration={100}>
                                                        <a>About Us</a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="services" smooth={true} duration={100}>
                                                        <a >Services</a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="ourwork" smooth={true} duration={100}>
                                                        <a >Our Work</a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="features" smooth={true} duration={100}>
                                                        <a >Our Features</a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="testimonials" smooth={true} duration={100}>
                                                        <a >Testimonials</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mt-md-0 mt-5">
                                        <div class="f_widget about-widget pl_70 wow fadeInLeft mainContents" data-wow-delay="0.6s" >
                                            <h3 class="f-title f_600 t_color f_size_18">Help</h3>
                                            <ul class="list-unstyled f_list">
                                                <li>
                                                    <a onClick={() => window.open("https://dlktech.co.in/faq/")}>FAQ</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => window.open("https://dlktech.co.in/terms-and-conditions/")}>Term &amp; conditions</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => window.open("https://dlktech.co.in/privacy-policy/")}>Privacy Policy</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => window.open("https://dlktech.co.in/refund-policy/")}>Refund Policy</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => window.open("https://dlktech.co.in/technologies/")}>Technologies</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mt-md-0 mt-5">
                                        <div class="f_widget social-widget pl_70 wow fadeInLeft mainContents" data-wow-delay="0.8s" >
                                            <h3 class="f-title f_600 t_color f_size_18">Follow Us</h3>
                                            <div class="f_social_icon">
                                                <a class="fab fa-facebook" onClick={() => window.open("https://www.facebook.com/DlkTechnologies/")}><BsFacebook className='facebookIcon' /></a>
                                                <a class="fab fa-twitter" onClick={() => window.open("https://twitter.com/dlktechnologie/")}><GrTwitter /></a>
                                                <a class="fab fa-linkedin" onClick={() => window.open("https://www.linkedin.com/company/dlktechnologies/")}><GrLinkedinOption /></a>
                                                <a class="fab fa-pinterest" onClick={() => window.open("https://www.instagram.com/dlk_tech/")}><BsInstagram /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                        <div class="footer_bottom">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-12  mt-2 d-flex justify-content-center">
                                        <p class="mb-0 f_400"> © 2023. Design & Developed by DLK Technologies Pvt Ltd. All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <span class="banner-frame-one1"></span>
                <span class="banner-frame-two1"></span>
                <span class="banner-frame-three1"></span>
                <span class="banner-frame-four1"></span>
            </div>
            <div className='shape-top-left1 d-md-flex d-none' data-aos="fade-down" data-aos-delay="1000">
            </div>
            <div className='shape-bottom-right1 d-md-flex d-none' data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1000">
                <img src={face} className="faceFooter"></img>
            </div>
        </div>
    )
}

export default Section15