import React from 'react'
import "../../Assets/Styles/Sections/Section2.css"
import "../../Assets/Styles/Sections/Section10.css"
import about from "../../Assets/Images/web.jpg"
import shape1 from "../../Assets/Images/shape-1.png"
import { Tabs } from 'antd';
const onChange = (key) => {
    console.log(key);
};
const items = [
    {
        key: '1',
        label: `On-Time Delivery`,
        children: `We supply and issue the prime digital marketing and promotional services to the esteemed clients and customers at their convenience matching at their right period of time.`,
    },
    {
        key: '2',
        label: `Experienced Professionals`,
        children: `We acquire excellent and experienced professionals and expertise to ascertain projects and services with prompt accuracy.`,
    },
    {
        key: '3',
        label: `Complete Technical Support`,
        children: `Enriching the technical and technological services through our support and services. We offer incredible marketing, seo services and digitally marketing your business.`,
    },
];
const Section10 = () => {
    return (
        <div id='features' className='features-area1  pb-75'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-5 d-flex justify-content-center'>
                        <img src={about} className="aboutusImg" />
                    </div>
                    <div className='col-md-7 mt-5'>
                        <h2 className='aboutText d-flex justify-content-md-start justify-content-center'>OUR FEATURES</h2>
                        <div className='tabs '>
                            <Tabs
                                defaultActiveKey="1"
                                items={items}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='features-shape-1'>
                <img src={shape1} />
            </div>
        </div>
    )
}

export default Section10