import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section7.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ModalPage from './ModalPage';

const Section7 = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className='promo-text-area mt-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='inner-promo-txt'>
                            <div class="d-md-flex justify-content-between align-items-center">
                                <div class="sec-title1 mb-md-0 mb-5">
                                    <h2 class="text-white">READY TO TAKE YOUR BUSINESS TO THE NEXT LEVEL ?</h2>
                                </div>
                                <div class="btn-area d-flex justify-content-md-start justify-content-center">
                                    <a onClick={showModal} class="btn btn-default">CONTACT US</a>
                                </div>
                                <ModalPage
                                    onFinishFailed={onFinishFailed}
                                    onFinish={onFinish}
                                    handleCancel={handleCancel}
                                    showModal={showModal}
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    sendEmail={sendEmail}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <span class="banner-frame-one"></span>
                <span class="banner-frame-two"></span>
                <span class="banner-frame-three"></span>
                <span class="banner-frame-four"></span>
            </div>
            <div className='shape-top-left' data-aos="fade-down" data-aos-delay="1000">

            </div>
            <div className='shape-bottom-right ' data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="1000">

            </div>
        </div>
    )
}

export default Section7