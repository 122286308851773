import React from 'react'
import client1 from "../../Assets/Images/bing.png"
import client2 from "../../Assets/Images/fb.png"
import client3 from "../../Assets/Images/hubspot.png"
import client4 from "../../Assets/Images/partner.png"
import "../../Assets/Styles/Sections/Section13.css"

const Section13 = () => {
    return (
        <div className='pt-5 mb-5'>
            <div className='section-title1 mt-5'>
                <h5>Our Partners</h5>
                <h2>DLK Technologies Is With Partnered With</h2>
            </div>
            <div className='container mt-5'>
                <div className='container '>
                    <div className='col-12 col-md-12 d-flex flex-md-row flex-column gap-3'>
                        <div className='card partnerCard col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client1} />
                        </div>
                        <div className='card partnerCard col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client2} />
                        </div>
                        <div className='card partnerCard col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client3} />
                        </div>
                        <div className='card partnerCard col-md-3 d-flex justify-content-md-start justify-content-center'>
                            <img src={client4} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section13