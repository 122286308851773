import React from 'react'
import "../../Assets/Styles/Sections/Section12.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import pat from "../../Assets/Images/pat.png"
import test1 from "../../Assets/Images/test1.png"
import test2 from "../../Assets/Images/test2.png"
import test3 from "../../Assets/Images/test3.png"
// import test1 from "../../Assets/Images/test4.png"

const Section12 = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div  id='testimonials'className='container pt-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='testimonials-inner'>
                        <div class="title2 d-flex justify-content-center mt-4">
                            <h2 class="text-white">What Our <strong>Clients</strong> Say About Us</h2>
                        </div>
                        <div>
                            <Carousel
                                responsive={responsive}
                                infinite={true}
                                autoPlay={true}
                                // autoPlaySpeed={1000}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                <div className='caroseulMain1'>
                                    <div className='col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart1'>
                                        <div className='col col-md-4 col-12 mt-5'>
                                            <div className='d-flex justify-content-md-end justify-content-center'>
                                                <div className=''>
                                                    <img src={pat}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col col-md-8 col-12'>
                                            <div className=' d-flex flex-column justify-content-start p-md-5 clientReview'>
                                                <h2>
                                                    Dear Rajkumar and DLK Team
                                                    <br />
                                                    Thanks for all your efforts.Congratulations on completion of the projects.Look forward to further interactions on other projects.
                                                    <br />
                                                    All the Best
                                                </h2>
                                                <div className='col-12 testimonials-designation'>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <strong class="d-block">Ram.Venkataramani</strong>
                                                    </div>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <span class="d-block">Managing Director</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='caroseulMain1'>
                                    <div className='col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart1'>
                                        <div className='col col-md-4 col-12 mt-5'>
                                            <div className='d-flex justify-content-md-end justify-content-center'>
                                                <div className=''>
                                                    <img src={test2}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col col-md-8 col-12'>
                                            <div className=' d-flex flex-column justify-content-start p-md-5 clientReview'>
                                                <h2>
                                                    Hi Rajkumar,
                                                    <br />
                                                    On behalf of George Oakes, I am writing this email with great pleasure and happiness on successfully completing two projects.
                                                    <br />
                                                    As per our scope, a new website is developed and existing site is upgraded at your end. I would like to congratulate all the team members for their assistance in completing the project with perfection.

                                                    I specially thank you for your extreme support and service in completing this project.

                                                    We request you to hand over all the credentials associated with the two websites.
                                                </h2>
                                                <div className='col-12 testimonials-designation'>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <strong class="d-block">Mohan</strong>
                                                    </div>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <span class="d-block">George Oakes</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='caroseulMain1'>
                                    <div className='col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart1'>
                                        <div className='col col-md-4 col-12 mt-5'>
                                            <div className='d-flex justify-content-md-end justify-content-center'>
                                                <div className=''>
                                                    <img src={test1}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col col-md-8 col-12'>
                                            <div className=' d-flex flex-column justify-content-start p-md-5 clientReview'>
                                                <h2>
                                                    Hi Team,
                                                    <br />
                                                    Thank you so much update sir .. good improvement is there , kindly continue the same,
                                                </h2>
                                                <div className='col-12 testimonials-designation'>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <strong class="d-block">Saravanan</strong>
                                                    </div>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <span class="d-block">Balaji Travels</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='caroseulMain1'>
                                    <div className='col col-md-12 col-12 d-flex flex-md-row flex-column caroseulSubPart1'>
                                        <div className='col col-md-4 col-12 mt-5'>
                                            <div className='d-flex justify-content-md-end justify-content-center'>
                                                <div className=''>
                                                    <img src={test3}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col col-md-8 col-12'>
                                            <div className=' d-flex flex-column justify-content-start p-md-5 clientReview'>
                                                <h2>
                                                    Hi Mr.Rajkumar,
                                                    <br />
                                                    Very glad with your professional approach. Keep it up. We shall take review for final uploading tomorrow.
                                                    <br />
                                                    Sending the attachment. Take my views for Web Invite poster for social media and thumbnail YouTube.
                                                    menu corner slot (paid advertisement) with dynamic link to the website. We can go ahead with this first.
                                                    Send the final version soon.
                                                    If you need, I can come to Chennai to finalise promo plan. Let me know.
                                                </h2>
                                                <div className='col-12 testimonials-designation'>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <strong class="d-block">Regards,</strong>
                                                    </div>
                                                    <div className='d-flex justify-content-center justify-content-md-start'>
                                                        <span class="d-block">Uncle Raj Sripathi</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                        <div class="dots-shape"></div>
                        <div class="testimonial-big-round"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section12