import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section4.css"
import digital from "../../Assets/Images/digital.png"
import AOS from 'aos';
import 'aos/dist/aos.css'
import ModalPage from './ModalPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Section4 = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000
    });
    return (
        <div className='mb-md-5 pt-3 col-12 col-md-12 section4Main mt-5'>
            <div id="card-container" data-offset="2" data-aos="fade-up" data-aos-delay="50">
                <div className='round-shape-blue-colored'></div>
                <div class="pg d-md-flex d-none">
                    <img src={digital} />
                </div>
                <div id="card">
                    <div class="shine"></div>
                    <div class=" text-block">
                        <h2 class="meg_title mb-4">We offer end to end digital solutions for Your<br /> Business to Grow Online</h2>
                        <p>
                            As a Digital Marketing Services provider in India, we can help your business grow
                            – not just by increasing traffic and rankings, but also by increasing leads and sales.
                            Our company specializes in SEO, SEM, Social Media Marketing (SMM), Online Reputation
                            Management (ORM), Branding Strategy, Content Marketing, and Performance Marketing.
                        </p>
                        <div class="btn-area d-flex justify-content-md-start justify-content-center" >
                            <a class="btn btn-default" onClick={showModal}>CONTACT US</a>
                        </div>
                        <ModalPage
                            onFinishFailed={onFinishFailed}
                            onFinish={onFinish}
                            handleCancel={handleCancel}
                            showModal={showModal}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            sendEmail={sendEmail}
                            form={form}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4