import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section8.css"
import girl1 from "../../Assets/Images/girl.png"
import { BsFillTelephoneFill } from "react-icons/bs"
import { GrMail } from "react-icons/gr"
import { ImLocation2 } from "react-icons/im"
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const Section8 = () => {
    const recaptchaRef = React.createRef();
    function onChange(value) {
        // console.log("Captcha value:", value);
        setIsModalOpen(true)
    }
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000
    });
    return (
        <div className='mt-5 pt-5'>
            <div className='bg1 bg-pattern-6'></div>

            <div class="auto-container">
                <div class="row">
                    <div class="title-column col-lg-6 col-md-12" data-aos="fade-right" data-aos-delay="100">
                        <div class="inner-column">
                            <div class="sec-title">
                                <span class="sub-title">contact us</span>
                                <h2>let’s work together?</h2>
                                <div class="text">Best Strategic digital marketing for your company's growth.</div>
                            </div>
                            <div class="contact-info-block">
                                <div class="inner">
                                    <i class="icon fa fa-phone"><BsFillTelephoneFill /></i>
                                    <h6 class="title">Have any question?</h6>
                                    <div class="text mt-2">Free <a href="tel:230009850">+91 7299951536</a></div>
                                </div>
                            </div>


                            <div class="contact-info-block">
                                <div class="inner">
                                    <i class="icon fa fa-envelope"><GrMail /></i>
                                    <h6 class="title">Send email</h6>
                                    <div class="text mt-2"><a href="mailto:needhelp@company.com">info@dlktech.co.in</a></div>
                                </div>
                            </div>

                            <div class="contact-info-block">
                                <div class="inner">
                                    <i class="icon fa fa-map-marker-alt"><ImLocation2 /></i>
                                    <h6 class="title">Visit anytime</h6>
                                    <div class="text mt-2">DLK Technologies Pvt Ltd.<br />
                                        No:68/70 Ground Floor,Raahat Plaza, No. 172,
                                        Arcot Road,Vadapalani,
                                        Chennai, Tamil Nadu 600026</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <div class="contact-form " >
                                <h3 class="title">CONTACT US</h3>

                                <form method="post" action="get" id="contact-form" data-aos="fade-right" data-aos-delay="100">
                                    <div class="row">
                                        <div class="form-group col-lg-12">
                                            <input type="text" name="full_name" placeholder="Your Name" required="" />
                                        </div>

                                        <div class="form-group col-lg-12">
                                            <input type="email" name="Email" placeholder="Email Address" required="" />
                                        </div>
                                        <div class="form-group col-lg-12">
                                            <input type="number" name="phone" placeholder="Phone" required="" />
                                        </div>
                                        <div class="form-group col-lg-12">
                                            <textarea name="message" placeholder="Message" required=""></textarea>
                                        </div>
                                        <div className='mt-2'>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6Lf5ZhslAAAAAH_E_1wxqGjNgemwye4d6tMIn5wj"
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div class="form-group col-lg-12 mt-2">
                                            <button class="theme-btn btn-style-one hvr-dark" type="submit" name="submit-form"><span class="btn-title">Write a Message</span></button>
                                        </div>
                                    </div>
                                </form>
                                <div className='bg-image d-md-flex d-none' data-aos="fade-left" data-aos-delay="100">
                                    <img src={girl1} className="girlImg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section8