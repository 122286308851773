import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section1.scss"
import banner from "../../Assets/Images/banner.png"
import { VscWorkspaceTrusted } from "react-icons/vsc"
import { FaWpforms } from "react-icons/fa"
import { BsGraphUpArrow } from "react-icons/bs"
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const Section1 = () => {
    const recaptchaRef = React.createRef();
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000,
    });
    return (
        <div className="main-banner-area">
            <div className="px-md-5 p-md-0 p-4">
                <div className='col-12 d-md-flex flex-md-row flex-column align-items-center justify-content-center'>
                    <div className='col-md-6 col-12' data-aos="fade-right" data-aos-delay="50">
                        <div className='container main-banner-content'>
                            <h5 className='mt-2'>
                                Drive success by unlocking the power of digital business through <span className='highlight'> Google Ads
                                    Facebook & Instagram Advertising | PPC Marketing | Social Media Management |
                                    Content Marketing | Graphic Design</span>
                            </h5>
                            <h2 className='mt-4'>Best Strategic digital marketing for your company's growth</h2>
                            <div class="mainCard card8 mt-5">
                                <div className='d-flex align-items-center'>
                                    <h6 className='px-3 main-banner-cardText mt-md-3'><VscWorkspaceTrusted className='main-banner-cardTextIcon' /></h6>
                                    <h6 className='mt-md-4 mt-2 main-banner-cardText'>
                                        Trusted by 500+ National and Global Brands
                                    </h6>
                                </div>
                            </div>
                            <div class="mainCard card8 mt-5">
                                <div className='d-flex align-items-center'>
                                    <h6 className='px-3 main-banner-cardText mt-md-3'><FaWpforms className='main-banner-cardTextIcon' /></h6>
                                    <h6 className='mt-md-4 mt-2 main-banner-cardText'>
                                        Over 20K leads were generated for our client last quarter
                                    </h6>
                                </div>
                            </div>
                            <div class="mainCard card8 mt-5">
                                <div className='d-flex align-items-center'>
                                    <h6 className='px-3 main-banner-cardText'><BsGraphUpArrow className='main-banner-cardTextIcon' /></h6>
                                    <h6 className='mt-2 main-banner-cardText'>
                                        A proven track record of success in ROI-driven online marketing for more than 12 years</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 d-flex justify-content-center'>
                        <div className='col-6 bannerImg1 d-md-flex d-none'>
                            <img src={banner} ></img>
                        </div>
                        <div class="login" data-aos="fade" data-aos-delay="50">
                            <div class="price-box">
                                <div class="ribbon">
                                    <span>Enquiry</span>
                                </div>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div>
                                    <label for="exampleInputEmail1" class="form-label formLabel">
                                        Name
                                    </label>
                                    <input
                                        id="ContactForm1_contact-form-name"
                                        size="30"
                                        required
                                        type="text"
                                        name="user_name"
                                        class="form-control"
                                    />
                                </div>
                                <div class="mt-3">
                                    <label for="exampleInputPassword1" class="form-label formLabel">
                                        Email
                                    </label>
                                    <input
                                        id="ContactForm1_contact-form-email"
                                        required
                                        size="30"
                                        type="email"
                                        name="user_email"
                                        class="form-control"
                                    />
                                </div>
                                <div class="mt-3">
                                    <label for="exampleInputPassword1" class="form-label formLabel">
                                        Phone
                                    </label>
                                    <input
                                        required
                                        type="number"
                                        class="form-control"
                                        id="ContactForm1_contact-form-phone"
                                        name="user_phone"
                                        size="11"
                                    />
                                </div>
                                <div class="mt-3">
                                    <label for="exampleInputPassword1" class="form-label formLabel">
                                        Message
                                    </label>
                                    <textarea
                                        required
                                        id="ContactForm1_contact-form-email-message"
                                        class="form-control"
                                        rows="3"
                                        name="message"
                                    ></textarea>
                                </div>
                                <div className='mt-2'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Lf5ZhslAAAAAH_E_1wxqGjNgemwye4d6tMIn5wj"
                                        onChange={onChange}
                                    />
                                </div>
                                <div class="col-12 text-center mt-3 ">
                                    <div className='banner-btn'>
                                        <button
                                            type="submit"
                                            id="ContactForm1_contact-form-submit"
                                            className='default-btn'
                                        >
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer className="mt-5 pt-2" />
        </div>
    )
}

export default Section1